<template>
  <div class="docs-card mt-8">
    <div class="docs-card-header">
      <h4 class="font-size-h2 text-dark-65 my-3">
        {{ $t("create_return") }}
      </h4>
    </div>
    <div class="docs-card-body px-8 pb-5">
      <p class="mt-6 mb-6 text-dark-65">
        {{ $t("integrate_with_the_following_endpoint") }}
      </p>
      <h5 class="font-size-h3 text-dark-65 mt-10 mb-4">
        {{ $t("request_endpoint") }}
      </h5>
      <!-- request method -->
      {{ $t("the_request_method") }}<code>POST</code>
      <p class="mt-6 mb-6 text-dark-65">
        {{ $t("the_request_end_point") }}
        <code>{url}/api/v3/returns/create</code>
      </p>
      <!-- request header -->
      <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
        {{ $t("request_header") }}
      </h5>
      <div class="highlight mb-5">
        <div class="highlight-code">
          <span class="token-tag">Authorization: </span>
          <span class="token-value">"Bearer [TOKEN]",</span>
          <br />
          <span class="token-tag">Content-type: </span>
          <span class="token-value">"application/json; charset=utf-8",</span>
          <br />
          <span class="token-tag">Accept: </span>
          <span class="token-value">"application/json</span>
          <br />
        </div>
      </div>
      <!-- body -->
      <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
        {{ $t("request_body") }}
      </h5>
      <div class="highlight">
        <button
          class="btn"
          :class="{
            'highlight-copy-rtl': $vuetify.rtl,
            'highlight-copy': !$vuetify.rtl,
          }"
          @click="copyCreateReturnBody"
        >
          {{ copyCreateReturnBodyButtonText }}
        </button>
        <div class="highlight-code">
          <span class="token-string">{</span>
          <div class="ml-4">
            <span class="token-tag">"client_reference": </span>
            <span class="token-value">"REFERENCE_NUMBER",</span>
            <br />
            <span class="token-tag">"original_awb": </span>
            <span class="token-value">"ORIGINAL_AWB",</span>
            <br />
            <span class="token-tag">"collection_type": </span>
            <span class="token-value">"pickup",</span>
            <span class="token-comment ml-8"> //dropoff, pickup</span>
            <br />
            <span class="token-tag">"drop_off_office": </span>
            <span class="token-value">"220381",</span>
            <span class="token-comment ml-8"> // office ID</span>
            <br />
            <span class="token-tag">"collection_address_type": </span>
            <span class="token-value">"6",</span>
            <span class="token-comment ml-8">
              //NATIONAL = 2, DESCRIPTIVE = 6</span
            >
            <br />
            <span class="token-tag">"short_address": </span>
            <span class="token-value">"",</span>
            <br />
            <span class="token-tag">"expected_collection_date": </span>
            <span class="token-value">"DATE_TIME",</span>
            <br />
            <span class="token-tag">"warehouse": </span>
            <span class="token-value">"WAREHOUSE_ID",</span>
            <br />
            <span class="token-tag">"collection_address": </span>
            <span class="token-value">{</span>
            <br />
            <div class="ml-4">
              <span class="token-tag">"name": </span>
              <span class="token-value">"VALUE",</span>
              <br />
              <span class="token-tag">"phone": </span>
              <span class="token-value">"VALUE",</span>
              <br />
              <span class="token-tag">"state": </span>
              <span class="token-value">"VALUE",</span>
              <br />
              <span class="token-tag">"city": </span>
              <span class="token-value">"VALUE",</span>
              <br />
              <span class="token-tag">"area": </span>
              <span class="token-value">"VALUE",</span>
              <br />
              <span class="token-tag">"address": </span>
              <span class="token-value">"VALUE",</span>
              <br />
              <span class="token-tag">"postal_code": </span>
              <span class="token-value">"VALUE",</span>
              <span class="token-comment ml-8">
                //required if address type is national</span
              >
              <br />
              <span class="token-tag">"building_no": </span>
              <span class="token-value">"",</span>
              <span class="token-comment ml-8">
                //required if address type is national</span
              >
              <br />
              <span class="token-tag">"unit_no": </span>
              <span class="token-value">"",</span>
              <span class="token-comment ml-8">
                //required if address type is national</span
              >
              <br />
              <span class="token-tag">"additional_no": </span>
              <span class="token-value">"",</span>
              <span class="token-comment ml-8">
                //required if address type is national</span
              >
              <br />
              <span class="token-tag">"address_2": </span>
              <span class="token-value">"VALUE",</span>
              <br />
            </div>
            <span class="token-string">}</span>
            <br />
            <span class="token-tag">"items": </span>
            <span class="token-string">[</span>
            <br />
            <div class="ml-4">
              <span class="token-string">{</span>
              <div class="ml-4">
                <span class="token-tag">"category": </span>
                <span class="token-value">"VALUE",</span>
                <br />
                <span class="token-tag">"length": </span>
                <span class="token-value">"VALUE",</span>
                <br />
                <span class="token-tag">"width": </span>
                <span class="token-value">"VALUE",</span>
                <br />
                <span class="token-tag">"height": </span>
                <span class="token-value">"VALUE",</span>
                <br />
                <span class="token-tag">"price": </span>
                <span class="token-value">"VALUE",</span>
                <br />
                <span class="token-tag">"weight": </span>
                <span class="token-value">"VALUE",</span>
                <br />
                <span class="token-tag">"description": </span>
                <span class="token-value">"VALUE"</span>
              </div>
              <br />
              <span class="token-string">}</span>
            </div>
            <span class="token-string">]</span>
          </div>
          <span class="token-string">}</span>
        </div>
      </div>
      <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
        {{ $t("expected_success_response") }}
      </h5>
      <div class="highlight">
        <div class="highlight-code">
          <span class="token-string">{</span>
          <div class="ml-4">
            <span class="token-tag">"success": </span>
            <span class="token-value">true,</span>
            <br />
            <span class="token-tag">"message": </span>
            <span class="token-value">"Successfully created.",</span>
            <br />
            <span class="token-tag">"client_reference": </span>
            <span class="token-value">"REFERENCE_NUMBER",</span>
            <br />
          </div>
          <span class="token-string">}</span>
        </div>
      </div>
      <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
        {{ $t("expected_failed_validation_response") }}
      </h5>

      <div class="highlight">
        <div class="highlight-code">
          <span class="token-string">{</span>
          <div class="ml-4">
            <span class="token-tag">success: </span>
            <span class="token-value">false</span>
            <br />
            <span class="token-tag">code: </span>
            <span class="token-value">400</span>
            <br />
            <span class="token-tag">message: </span>
            <span class="token-value">{</span>
            <br />
            <div class="ml-4">
              <span class="token-tag">customer: </span>
              <span class="token-value">[</span>
              <br />
              <div class="ml-4">
                <span class="token-value"
                  >"The selected Customer is invalid."</span
                >
                <br />
              </div>
              <span class="token-string">],</span>
              <br />
              <span class="token-tag">client_reference: </span>
              <span class="token-value">[</span>
              <br />
              <div class="ml-4">
                <span class="token-value"
                  >"The selected Client reference is invalid."</span
                >
                <br />
              </div>
              <span class="token-string">],</span>
            </div>
            <span class="token-string">}</span>
          </div>
          <span class="token-string">}</span>
        </div>
      </div>

      <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
        {{ $t("exception_response") }}
      </h5>

      <div class="highlight">
        <div class="highlight-code">
          <span class="token-string">{</span>
          <div class="ml-4">
            <span class="token-tag">success: </span>
            <span class="token-value">false,</span>
            <br />
            <span class="token-tag">code: </span>
            <span class="token-value">500,</span>
            <br />
            <span class="token-tag">message: </span>
            <span class="token-value">"Failed to create"</span>
            <br />
          </div>
          <span class="token-string">}</span>
        </div>
      </div>

      <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
        {{ $t("api_reference") }}
      </h5>
      <div class="table-responsive border rounded mt-8">
        <table class="table table-striped align-top mb-0 g-5">
          <thead>
            <tr class="fs-4 fw-bold text-dark p-6">
              <th class="min-w-250px">
                {{ $t("name") }}
              </th>
              <th class="min-w-100px">
                {{ $t("type") }}
              </th>
              <th class="min-w-100px">
                {{ $t("parameter") }}
              </th>
              <th class="min-w-500px">
                {{ $t("description") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- client ref -->
            <tr>
              <td>
                <code>client_reference</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("clients_reference_for_the_return") }}
              </td>
            </tr>
            <!-- original awb -->
            <tr>
              <td>
                <code>original_awb</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">optional</code>
              </td>
              <td>{{ $t("orders_original_awb") }}</td>
            </tr>
            <!-- collection type -->
            <tr>
              <td>
                <code>collection_type</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("collection_type") }}<br /><br /><code class="ml-0"
                  >dropoff</code
                >
                or
                <code>pickup</code>
              </td>
            </tr>
            <!-- drop off office -->
            <tr>
              <td>
                <code>drop_off_office</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>{{ $t("drop_off_office") }}</td>
            </tr>
            <!-- collection address type -->
            <tr>
              <td>
                <code>collection_address_type</code>
              </td>
              <td><code>number</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("collection_address_type") }}<br /><br /><code
                  class="ml-0"
                  >2</code
                >
                or
                <code>6</code>
              </td>
            </tr>
            <!-- short address -->
            <tr>
              <td>
                <code>short_address</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>{{ $t("short_address") }}</td>
            </tr>
            <!-- original client ref -->
            <tr>
              <td>
                <code>original_client_reference</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("orders_original_client_reference") }}
              </td>
            </tr>
            <!-- collection date -->
            <tr>
              <td>
                <code>expected_collection_date</code>
              </td>
              <td><code>datetime</code></td>
              <td>
                <code class="ms-0">optional</code>
              </td>
              <td>
                {{ $t("expected_collection_date_time_for_the_return_format") }}
                <code>YYYY-MM-DD HH:MM:SS</code>
                <br />
                {{ $t("example") }}:
                <code>2012-12-12 12:12:12</code>
              </td>
            </tr>
            <!-- warehouse -->
            <tr>
              <td>
                <code>warehouse</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">optional</code>
              </td>
              <td>{{ $t("destination_warehouse") }}</td>
            </tr>
            <!-- name -->
            <tr>
              <td>
                <code>collection_address.name</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>{{ $t("name_of_the_sender") }}</td>
            </tr>
            <!-- phone -->
            <tr>
              <td>
                <code>collection_address.phone</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("phone_number_of_the_sender") }}
              </td>
            </tr>
            <!-- state -->
            <tr>
              <td>
                <code>collection_address.state</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{
                  $t(
                    "location_of_the_sender_i_e_the_state_or_province_in_which_they_reside"
                  )
                }}
              </td>
            </tr>
            <!-- city -->
            <tr>
              <td>
                <code>collection_address.city</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>{{ $t("city_of_the_sender") }}</td>
            </tr>
            <!-- area -->
            <tr>
              <td>
                <code>collection_address.area</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">optional</code>
              </td>
              <td>{{ $t("area_of_the_sender") }}</td>
            </tr>
            <!-- address -->
            <tr>
              <td>
                <code>collection_address.address</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>{{ $t("address_of_the_sender") }}</td>
            </tr>
            <!-- postal code -->
            <tr>
              <td>
                <code>collection_address.postal_code</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("collection_type_of_the_sender") }} <br /><br />
                <code class="ml-0">required if address type is national</code>
              </td>
            </tr>
            <!-- building no -->
            <tr>
              <td>
                <code>collection_address.building_no</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("building_no_of_the_sender") }}<br /><br />
                <code class="ml-0">required if address type is national</code>
              </td>
            </tr>
            <!-- unit no -->
            <tr>
              <td>
                <code>collection_address.unit_no</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("unit_no_of_the_sender") }}<br /><br />
                <code class="ml-0">required if address type is national</code>
              </td>
            </tr>
            <!-- additional no -->
            <tr>
              <td>
                <code>collection_address.additional_no <br /><br /> </code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("additional_no_of_the_sender") }} <br /><br />
                <code class="ml-0">required if address type is national</code>
              </td>
            </tr>
            <!-- address_2 -->
            <tr>
              <td>
                <code>collection_address.address_2</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("additional_address_of_the_sender") }}<br /><br />
                <code class="ml-0">required if address type is national</code>
              </td>
            </tr>
            <!-- category -->
            <tr>
              <td>
                <code>item.category</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>
                {{ $t("senders_additional_address") }}
                <br />
                <br />
                {{ $t("available_options") }}
                <code>Food</code>, <code>Clothes</code>,
                <code>Medical Equipments</code>,
                <code>Electrical Appliances</code>,
                <code>Electronic Devices</code>, <code>Medicines</code>,
                <code>Toys</code>, <code>Paper & Documents</code>,
                <code>Other</code>
              </td>
            </tr>
            <!-- length -->
            <tr>
              <td>
                <code>items.length</code>
              </td>
              <td><code>number</code></td>
              <td>
                <code class="ms-0">optional</code>
              </td>
              <td>{{ $t("item_length") }}</td>
            </tr>
            <!-- width -->
            <tr>
              <td>
                <code>items.width</code>
              </td>
              <td><code>number</code></td>
              <td>
                <code class="ms-0">optional</code>
              </td>
              <td>{{ $t("item_width") }}</td>
            </tr>
            <!-- height -->
            <tr>
              <td>
                <code>items.height</code>
              </td>
              <td><code>number</code></td>
              <td>
                <code class="ms-0">optional</code>
              </td>
              <td>{{ $t("item_height") }}</td>
            </tr>
            <!-- price -->
            <tr>
              <td>
                <code>items.price</code>
              </td>
              <td><code>number</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>{{ $t("item_price") }}</td>
            </tr>
            <!-- weight -->
            <tr>
              <td>
                <code>items.weight</code>
              </td>
              <td><code>number</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>{{ $t("item_weight") }}</td>
            </tr>
            <!-- description -->
            <tr>
              <td>
                <code>items.description</code>
              </td>
              <td><code>string</code></td>
              <td>
                <code class="ms-0">mandatory</code>
              </td>
              <td>{{ $t("item_description") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateReturn",
  data: function () {
    return {
      table_properties: {
        title: "Return API Integration",
        description: "Integrate with Ease. Customize with Confidence.",
      },
      copyLoginUrlButtonText: "copy",
      copyLoginBodyButtonText: "copy",
      copyCreateReturnBodyButtonText: "copy",
      copyGetLocationButtonText: "copy",
      copyGetLocationBodyButtonText: "copy",
    };
  },
  methods: {
    copyLoginUrl() {
      const snippet = `/auth/login`;
      navigator.clipboard.writeText(snippet);
      this.copyReqButtonText = "copied";
    },
    copyLoginBody() {
      const snippet = `{email: [YOUR_EMAIL],
password: [YOUR_PASSWORD]
}`;
      navigator.clipboard.writeText(snippet);
      this.copyLoginBodyButtonText = "copied";
    },
    copyRequest() {
      const snippet = `{
  "customer": "STL7VaB9g1",
}`;
      navigator.clipboard.writeText(snippet);
      this.copyReqButtonText = "copied";
    },

    copyCreateReturnBody() {
      const snippet = `{
    "client_reference":"YOUR_TOKEN", 
    "original_client_reference": "YOUR_TOKEN", 
    "original_awb": "AWB", 
    "collection_type": "pickup",
    "drop_off_office": "VALUE",
    "collection_address_type": "VALUE",
    "short_address": "",
    "expected_collection_date": "YYYY-MM-DD HH:MM:SS", 
    "warehouse":"[WAREHOUSE]", 
    "collection_address": {
        "name": "[NAME]", 
        "phone": "[PHONE]", 
        "state": "[STATE]", 
        "city": "[CITY]", 
        "area": "[AREA]", 
        "address": "[ADDRESS]", 
        "postal_code": "[POSTAL_CODE]", 
        "building_no": "[BUILDING_NO]", 
        "unit_no": "[UNIT_NO]", 
        "additional_no": "[ADDITIONAL_NO]", 
        "address_2": "[ADDRESS]" 
    },
    "items": [
        {
            "category": "[CATEGORY]", 
            "length": [NUM], 
            "width": [NUM], 
            "height": [NUM], 
            "price": [NUM], 
            "weight": [NUM],
            "description":"[DES]"
        }
    ]
}`;
      navigator.clipboard.writeText(snippet);
      this.copyCreateReturnBodyButtonText = "copied";
    },
    copyLocationUrl() {
      const snippet = `/api/v3/returns/locations`;
      navigator.clipboard.writeText(snippet);
      this.copyGetLocationButtonText = "copied";
    },
    copyGetLocationBody() {
      const snippet = `{
"country_code": "KSA",
}`;
      navigator.clipboard.writeText(snippet);
      this.copyGetLocationBodyButtonText = "copied";
    },
  },
};
</script>

<style lang="scss">
.docs-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 0.75rem;
  border: 1px solid #f1f1f4;
}
.docs-card-header {
  display: flex;
  min-height: 56px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f4;
  padding: 0.75rem 1.875rem;
}
.card-title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #071437;
}
.card-body {
  flex-grow: 1;
  padding: 1.25rem 1.875rem;
}
</style>
