<template>
  <div class="docs-card mt-10">
    <div class="docs-card-header">
      <h4 class="font-size-h2 text-dark-65 my-3">
        {{ $t("get_location") }}
      </h4>
    </div>
    <div class="docs-card-body px-8 pb-5">
      <div>
        <h5 class="font-size-h3 text-dark-65 mt-6 mb-4">
          {{ $t("request_endpoint") }}
        </h5>
        <!-- request type -->
        {{ $t("the_request_method") }}:<code>GET</code>
        <p class="mt-6 mb-6 text-dark-65">
          {{ $t("the_request_end_point") }}:<code
            >/api/v3/returns/locations/{country_code}</code
          >
        </p>
        <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
          {{ $t("request_header") }}
        </h5>
        <div class="highlight mb-5">
          <div class="highlight-code">
            <span class="token-tag">Authorization: </span>
            <span class="token-value">"Bearer [TOKEN]",</span>
            <br />
            <span class="token-tag">Content-type: </span>
            <span class="token-value">"application/json; charset=utf-8",</span>
            <br />
            <span class="token-tag">Accept: </span>
            <span class="token-value">"application/json</span>
            <br />
            <span class="token-tag">data: </span>
            <span class="token-value">"application/json</span>
            <br />
          </div>
        </div>
        <!-- <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">Request body</h5>
            <div class="highlight mb-5">
              <button class="highlight-copy btn" @click="copyGetLocationBody">
                {{ copyGetLocationBodyButtonText }}
              </button>
              <div class="highlight-code">
                <span class="token-string">{</span>
                <div class="ml-4">
                  <span class="token-tag">"country_code": </span>
                  <span class="token-value">"KSA",</span>
                  <br />
                </div>
                <span class="token-string">}</span>
              </div>
            </div> -->
        <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
          {{ $t("expected_success_response") }}
        </h5>
        <div class="highlight mb-5">
          <div class="highlight-code">
            <span class="token-string">{</span>
            <div class="ml-4">
              <span class="token-tag">"success": </span>
              <span class="token-value">true,</span>
              <br />
              <span class="token-tag">"data": </span>
              <span class="token-string">{</span>
              <br />
              <div class="ml-4">
                <span class="token-tag">"country_id": </span>
                <span class="token-value">191</span>
                <br />
                <span class="token-tag">"country_name": </span>
                <span class="token-value">"Saudi Arabia",</span>
                <br />
                <span class="token-tag">"country_code": </span>
                <span class="token-value">"KSA",</span>
                <br />
                <span class="token-tag">"country_shortCode": </span>
                <span class="token-value">"SA",</span>
                <br />
                <span class="token-tag">"states": </span>
                <span class="token-string">[</span>
                <div class="ml-4">
                  <div class="ml-4">
                    <span class="token-string">{ </span>
                    <br />
                    <div class="ml-4">
                      <span class="token-tag">"stated_id": </span>
                      <span class="token-value">4341</span>
                      <br />
                      <span class="token-tag">"state_name": </span>
                      <span class="token-value">Asir Principality</span>
                      <br />
                      <span class="token-tag">"cities": </span>
                      <span class="token-string">[ </span>
                      <br />
                      <div class="ml-4">
                        <span class="token-string">{ </span>
                        <br />
                        <div class="ml-4">
                          <span class="token-tag">"city_id": </span>
                          <span class="token-value">50443</span>
                          <br />
                          <span class="token-tag">"city_name": </span>
                          <span class="token-value">"Abha"</span>
                          <br />
                          <span class="token-tag">"areas": </span>
                          <span class="token-string">[</span>
                          <div class="ml-4">
                            <span class="token-string">{ </span>
                            <br />
                            <div class="ml-4">
                              <span class="token-tag">"area_id": </span>
                              <span class="token-value">45,</span>
                              <br />
                              <span class="token-tag">"area_name": </span>
                              <span class="token-value">"Ad Dilam",</span>
                              <br />
                            </div>
                            <span class="token-string">} </span>
                            <br />
                          </div>
                          <span class="token-string">] </span>
                          <br />
                        </div>

                        <span class="token-string">} </span>
                      </div>
                      <span class="token-string">] </span>
                    </div>
                    <span class="token-string">} </span>
                    <br />
                  </div>
                </div>
              </div>
              <span class="token-string">] </span>
              <br />
            </div>
            <span class="token-string">}</span>
          </div>
        </div>

        <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
          {{ $t("expected_failed_validation_response") }}
        </h5>

        <div class="highlight">
          <div class="highlight-code">
            <span class="token-string">{</span>
            <div class="ml-4">
              <span class="token-tag">success: </span>
              <span class="token-value">false,</span>
              <br />
              <span class="token-tag">code: </span>
              <span class="token-value">422,</span>
              <br />
              <span class="token-tag">errors: </span>
              <span class="token-value">{</span>
              <br />
              <div class="ml-4">
                <span class="token-tag">country_code: </span>
                <span class="token-string">[</span>
                <br />
                <div class="ml-4">
                  <span class="token-value"
                    >"The selected country code is invalid."</span
                  >
                  <br />
                </div>
                <span class="token-string">],</span>
                <br />
              </div>
              <span class="token-string">}</span>
            </div>
            <span class="token-string">}</span>
          </div>
        </div>

        <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
          {{ $t("exception_response") }}
        </h5>

        <div class="highlight">
          <div class="highlight-code">
            <span class="token-string">{</span>
            <div class="ml-4">
              <span class="token-tag">success: </span>
              <span class="token-value">false,</span>
              <br />
              <span class="token-tag">code: </span>
              <span class="token-value">500,</span>
              <br />
              <span class="token-tag">message: </span>
              <span class="token-value">"Failed to get locations"</span>
              <br />
            </div>
            <span class="token-string">}</span>
          </div>
        </div>

        <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
          {{ $t("api_reference") }}
        </h5>
        <div class="table-responsive border rounded mt-6">
          <table class="table table-striped align-top mb-0 g-5">
            <thead>
              <tr class="fs-4 fw-bold text-dark p-6">
                <th class="min-w-250px">
                  {{ $t("name") }}
                </th>
                <th class="min-w-250px">
                  {{ $t("type") }}
                </th>
                <th class="min-w-100px">
                  {{ $t("parameter") }}
                </th>
                <th class="min-w-500px">
                  {{ $t("description") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- client ref -->
              <tr>
                <td>
                  <code>country_code</code>
                </td>
                <td>
                  <code>string</code>
                </td>
                <td>
                  <code class="ms-0">mandatory</code>
                </td>
                <td>
                  {{ $t("the_country_code") }} <br />
                  {{ $t("minimum_length_is") }}
                  <code>2</code> & <br />

                  {{ $t("maximum_length_is") }}
                  <code>3</code>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
